/* --------------------------- Footer START ---------------------------------- */

@media screen and (min-width: 1400px) {
    .footer .container {
        max-width: 1830px;
    }
}

.footer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top: auto;
    position: relative;
    padding: 80px 0 15px;
    background-color: #f7f7f7;
    -webkit-box-shadow: inset 0px 35px 29px -25px rgba(22, 22, 22, 0.04);
            box-shadow: inset 0px 35px 29px -25px rgba(22, 22, 22, 0.04);
}
.footer .footer_row .column {
    padding: 0 15px;
    margin-bottom: 60px;
}
.footer .footer_row .column:nth-child(1) {
    width: 25%;
}
.footer .footer_row .column:nth-child(2) {
    width: 35%;
}
.footer .footer_row .column:nth-child(3), 
.footer .footer_row .column:nth-child(4) {
    width: 20%;
}
.footer .footer_row .rights {
    display: block;
    font-size: 14px;
}
.footer .footer_row .logo {
    display: block;
    width: 250px;
    height: 80px;
    margin-bottom: 45px;
}
.footer .logo img {
    width: 100%;
    height: auto;
    -o-object-fit: contain;
       object-fit: contain;
}
.footer .footer_row .email_img {
    display: inline-block;
    width: 44px;
    height: 44px;
    -o-object-fit: contain;
       object-fit: contain;
    margin-right: 16px;
    margin-bottom: -6px;
}
.footer .title {
    font-family: "Baloo";
    font-size: 24px;
    color: #444;
    margin-bottom: 20px;
    display: inline-block;
}
.footer .footer_row .column:nth-child(1) .title {
    display: inline-block;
    margin-bottom: 35px;
}
.footer .footer_row .subscribe {
    max-width: 315px;
    width: 100%;
}
.footer .over-select-js-select {
    position: relative;
}
.footer label[for=select-your-pet-id] {
    position: absolute;
}
.footer .over-select-js-select .js-select-f {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    cursor: pointer;
    outline: none;
    padding: 14px 20px 13px;
    border-width: 1px;
    border-color: #e3e0fc;
    border-style: solid;
    border-radius: 5px;
    background-color: #fff;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    color: #222;
    font-size: 14px;
    margin-bottom: 15px;
    position: relative;
    line-height: 1.4;
    font-family: "SFProDisplay";
}
.footer .over-select-js-select:after {
    content: "";
    position: absolute;
    width: 9px;
    height: 5px;
    right: 20px;
    top: 50%;
    margin-top: -3px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background: url(../../img/select-down.png) center center no-repeat;
    background-size: contain;
}
.footer .footer_row .subscribe .input-wrapper {
    position: relative;
    width: 100%;
    height: 48px;
    margin-bottom: 32px;
    display: block;
}
.footer .input-wrapper input {
    cursor: pointer;
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid #e3e0fc;
    width: 100%;
    height: 100%;
    -webkit-font-variant-ligatures: none;
            font-variant-ligatures: none;
    line-height: 1.4;
    background-color: #fff;
    font-family: "SFProDisplay";
}
.footer .input-wrapper input:focus{
    border-color: #8074f2;
}
.footer .input-wrapper-in-p {
    position: absolute;
    z-index: 1;
    color: #222;
    font-size: 14px;
    font-weight: 400;
    top: 12px;
    /*left: 20px;*/
    left: 15px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    display: block;
    padding: 2px 6px;
    border-radius: 5px;
    background-color: #fff;
    white-space: nowrap;
    line-height: 1.4;
}
.footer .input-wrapper input:focus + .input-wrapper-in-p{
    top: -10px;
    font-size: 12px;
    color: #8074f2;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}
.footer .footer_row .subscribe button{
    border-radius: 10px;
    font-size: 20px;
    padding: 21px 10px;
    max-width: 315px;
    width: 100%;
    letter-spacing: 0;
}
.footer .bt-lapa span{
    padding: 20px 49px 17px 48px;
    font-size: 20px;
    border-radius: 10px;
    letter-spacing: 0;
}
.footer .bt-lapa svg {
    margin-right: 26px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
}
.footer .pets_list_item {
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 28px;
    border-radius: 50%;
    padding: 4px;
    -webkit-transition: all .2s;
    transition: all .2s;
    position: relative;
    z-index: 2;
}
.footer .pets_list_item:hover {
    background-color: #fdf5d1;
}
.footer .pets_list_item:nth-child(even):hover {
    background-color: #e3e0fc;
}
.footer .pets_list_item a {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-align-items: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: dotted 3px #ffce00;
    color: #222;
    text-decoration: none;
}
.footer .pets_list_item a img {
    display: block;
    width: 95px;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
}

@media (min-width: 768px) {
    .footer .pets_list_item a img {
        margin-top: 9px;
        margin-bottom: 10px;
    }
}
.footer .pets_list_item span {
    -webkit-box-shadow: 0px 4px 13px 0px rgb(25 25 25 / 17%);
            box-shadow: 0px 4px 13px 0px rgb(25 25 25 / 17%);
    -webkit-transition: all .1s;
    transition: all .1s;
    position: relative;
    bottom: 10px;
    width: 100%;
    border-radius: 5px;
    background-color: #fdf5d1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    padding: 4px;
}
.footer .pets_list_item:hover span {
    color: #8074f2;
    font-weight: 600;
}
.footer .pets_list_item span svg {
    margin-left: 9px;
    display: block;
    width: 11px;
    height: auto;
    fill: #222;
    -webkit-transition: all .1s;
    transition: all .1s;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
}
.footer .pets_list_item:hover span svg {
    fill: #8074f2;
}
.footer .footer_row .column:nth-child(3) a, 
.footer .footer_row .column:nth-child(4) a {
    color: #444;
    display: block;
    margin-bottom: 20px;
}
.footer .footer_row .column:nth-child(3) a:hover, 
.footer .footer_row .column:nth-child(4) a:hover {
    text-decoration: underline;
    color: #8074f2;
}
.footer .footer_row .address svg {
    display: inline-block;
    width: 12px;
    fill: #8074f2;
    margin-right: 14px;
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
}
.follow ul li {
    display: inline-block;
    margin-right: 15px;
    position: relative;
}
.follow ul li:last-child {
    margin-right: 0;
}
.footer .footer_row .column:nth-child(3) .follow ul li a, 
.footer .footer_row .column:nth-child(4) .follow ul li a,
.follow ul li a {
    position: relative;
    margin-bottom: 15px;
}
.follow ul li a img{
    display: block;
    width: 40px;
    height: 40px;
}
.footer .footer-image-con-media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.footer .footer_row .column:nth-child(4) .footer-image-con-media .footer-image-con-media-devo,
.footer .footer-image-con-media .footer-image-con-media-devo {
    display: table;
    margin-left: 15px;
    margin-bottom: 0;
}
.footer .footer_row {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-left: -15px;
    margin-right: -15px;
}
.footer .footer_row .mail svg {
    display: inline-block;
    width: 16px;
    fill: #8074f2;
    margin-right: 14px;
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
}
.footer .footer_row .column:nth-child(3) span, 
.footer .footer_row .column:nth-child(4) span {
    color: #444;
    display: block;
    margin-bottom: 20px;
}
.footer .footer_row .column:nth-child(3) .mail, 
.footer .footer_row .column:nth-child(4) .mail {
    margin-bottom: 40px;
}
.footer .footer_row .follow ul {
    margin-bottom: 75px;
    line-height: 0;
}
.footer .footer_row .pets_list .pets_list_item:nth-child(even) a {
    border-color: #8074f2;
}
.footer .footer_row .pets_list .pets_list_item:nth-child(even) span {
    background-color: #e3e0fc;
}

@media screen and (max-width: 1399px) {
    .footer .pets_list_item {
        margin-right: 8px;
    }
    .footer .pets_list_item a {
        width: 120px;
        height: 120px;
    }
    .footer .pets_list_item a img {
        width: 75px;
    }
    .footer .footer_row .column:nth-child(4) .footer-image-con-media .footer-image-con-media-devo,
    .footer .footer-image-con-media .footer-image-con-media-devo {
        margin-top: 15px;
        margin-left: 0;
    }
}

@media screen and (max-width: 1199px) {
    .footer .footer_row .logo {
        width: 185px;
        height: 60px;
    }
}

@media screen and (max-width: 991px) {
    .footer {
        padding: 50px 0 15px;
    }
    .footer .footer_row .column:nth-child(1),
    .footer .footer_row .column:nth-child(2),
    .footer .footer_row .column:nth-child(3),
    .footer .footer_row .column:nth-child(4) {
        width: 50%;
    }
    .footer .footer_row .follow ul {
        margin-bottom: 20px
    }
    .follow ul li {
        margin-right: 10px;
    }
    .footer .footer_row .column:nth-child(4) .footer-image-con-media .footer-image-con-media-devo,
    .footer .footer-image-con-media .footer-image-con-media-devo {
        margin-top: 0;
        margin-left: 15px;
    }
}

@media screen and (max-width: 767px) {
    .footer {
        padding: 50px 0 225px;
    }
    .footer .footer_row .column {
        margin-bottom: 30px;
    }
    .footer .footer_row .column:nth-child(1), 
    .footer .footer_row .column:nth-child(2) {
        text-align: center;
        width: 100%;
    }
    .footer .footer_row .logo{
        margin: 0 auto 30px;
    }
    .footer .title {
        font-size: 25px;
    }
    .footer .footer_row .subscribe {
        margin: 0 auto;
    }
    .footer .bt-lapa {
        margin: 0 auto;
    }
    .footer .footer_row .column:nth-child(3) a, 
    .footer .footer_row .column:nth-child(4) a {
        margin-bottom: 10px;
    }
    .footer .footer-image-con-media {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
    .footer .footer_row .rights {
        position: absolute;
        bottom: 15px
    }
    .footer .follow {
        position: absolute;
        left: 0;
        bottom: 60px;
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 575px) {
    .footer .title {
        font-size: 20px;
    }
    .footer .pets_list {
        margin-bottom: 20px;
    }
    .footer .pets_list_item a {
        width: 90px;
        height: 90px
    }
    .footer .pets_list_item a img {
        width: 60px
    }
    .footer .pets_list_item span {
        padding: 2px;
    }
    .footer .pets_list_item a span {
        font-size: 10px;
        min-width: 94px;
    }
    .footer .pets_list_item span svg {
        width: 9px;
    }
}

/* --------------------------- Footer END ---------------------------------- */