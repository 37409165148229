/* --------------------------- Header START ---------------------------------- */
.header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 100px;
    z-index: 3;
    -webkit-transition: height .3s linear;
    transition: height .3s linear;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 6px 60px 1px rgba(0, 0, 0, 0.07);
            box-shadow: 0px 6px 60px 1px rgba(0, 0, 0, 0.07);
}

.header.fixed-header {
    height: 66px;
}

.header-con{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.header .logo {
    display: block;
}
.header .logo svg{
    width: 240px;
    height: auto;
    -webkit-transition: width .3s linear;
    transition: width .3s linear;
}
.header.fixed-header .logo svg{
    width: 164px;
}
.header-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: auto;
}
.header-buttons-insurance {
    line-height: 0;
}
.header-buttons-insurance > * {
    max-width: 160px;
    width: 100vw;
    padding: 18px 15px 17px;
}
.header.fixed-header .header-buttons-insurance > * {
    padding: 13px 15px 12px;
}
.header-buttons-online {
    line-height: 0;
    margin-left: 22px;
}
.header.fixed-header .bt-lapa-small span{
    padding: 12px 27px 10px;
}
.header-buttons-search {
    padding-left: 43px;
}
.header-buttons-search > * {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.header-buttons-search > * span {
    font-family: "Baloo";
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    color: #444444;
    margin-left: 10px;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
}
.header-buttons-search > *:hover span{
    color: #8074F2;
}
.header-right {
    margin-left: 43px;
}
.menu-line-con {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
}
.menu-line {
    position: relative;
    width: 24px;
    height: 24px;
    overflow: hidden;
}
.menu-line-1,
.menu-line-2,
.menu-line-3,
.menu-line-4,
.menu-line-5{
    background-color: #8074F2;
    border-radius: 15px;
    position: absolute;
    height: 3px;
}
.menu-line-1,
.menu-line-2,
.menu-line-3{
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    left: 0;
}
.menu-line-4,
.menu-line-5{
    top: 10px;
    left: 0;
    right: 0;
}
.menu-line-1 {
    top: 3px;
    width: 14px;
}
.menu-line-2 {
    top: 10px;
    width: 19px;
}
.menu-line-3 {
    top: 17px;
    width: 24px;
}
.menu-line-4{
    -webkit-transform: rotate(45deg) translate(calc(-100% - 6px), 0);
            transform: rotate(45deg) translate(calc(-100% - 6px), 0);
}
.menu-line-5{
    -webkit-transform: rotate(-45deg) translate(calc(100% + 6px), 0);
            transform: rotate(-45deg) translate(calc(100% + 6px), 0);
}
.menu-line-con .menu-line-1{
    -webkit-transition: -webkit-transform 0.15s linear 0.25s;
    transition: -webkit-transform 0.15s linear 0.25s;
    transition: transform 0.15s linear 0.25s;
    transition: transform 0.15s linear 0.25s, -webkit-transform 0.15s linear 0.25s;
}
.menu-line-con .menu-line-2{
    -webkit-transition: -webkit-transform 0.15s linear 0.2s;
    transition: -webkit-transform 0.15s linear 0.2s;
    transition: transform 0.15s linear 0.2s;
    transition: transform 0.15s linear 0.2s, -webkit-transform 0.15s linear 0.2s;
}
.menu-line-con .menu-line-3{
    -webkit-transition: -webkit-transform 0.15s linear 0.15s;
    transition: -webkit-transform 0.15s linear 0.15s;
    transition: transform 0.15s linear 0.15s;
    transition: transform 0.15s linear 0.15s, -webkit-transform 0.15s linear 0.15s;
}
.menu-line-4,
.menu-line-5 {
    -webkit-transition: -webkit-transform 0.15s linear 0s;
    transition: -webkit-transform 0.15s linear 0s;
    transition: transform 0.15s linear 0s;
    transition: transform 0.15s linear 0s, -webkit-transform 0.15s linear 0s;
}
.menu-line-con.active .menu-line-1,
.menu-line-con.active .menu-line-2,
.menu-line-con.active .menu-line-3 {
    -webkit-transform: translateX(24px);
            transform: translateX(24px);
}
.menu-line-con.active .menu-line-1{
    -webkit-transition: -webkit-transform 0.15s linear 0s;
    transition: -webkit-transform 0.15s linear 0s;
    transition: transform 0.15s linear 0s;
    transition: transform 0.15s linear 0s, -webkit-transform 0.15s linear 0s;
}
.menu-line-con.active .menu-line-2{
    -webkit-transition: -webkit-transform 0.15s linear 0.05s;
    transition: -webkit-transform 0.15s linear 0.05s;
    transition: transform 0.15s linear 0.05s;
    transition: transform 0.15s linear 0.05s, -webkit-transform 0.15s linear 0.05s;
}
.menu-line-con.active .menu-line-3{
    -webkit-transition: -webkit-transform 0.15s linear 0.1s;
    transition: -webkit-transform 0.15s linear 0.1s;
    transition: transform 0.15s linear 0.1s;
    transition: transform 0.15s linear 0.1s, -webkit-transform 0.15s linear 0.1s;
}
.menu-line-con.active .menu-line-4,
.menu-line-con.active .menu-line-5 {
    -webkit-transition: -webkit-transform 0.15s linear 0.25s;
    transition: -webkit-transform 0.15s linear 0.25s;
    transition: transform 0.15s linear 0.25s;
    transition: transform 0.15s linear 0.25s, -webkit-transform 0.15s linear 0.25s;
}
.menu-line-con.active .menu-line-4{
    -webkit-transform: rotate(45deg) translate(0, 0);
            transform: rotate(45deg) translate(0, 0);
}
.menu-line-con.active .menu-line-5{
    -webkit-transform: rotate(-45deg) translate(0, 0);
            transform: rotate(-45deg) translate(0, 0);
}
.menu-text {
    padding-left: 10px;
}
.menu-text > * {
    font-family: "Baloo";
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    color: #444444;
    -webkit-transition: color 0.3s ease;
    transition: color 0.3s ease;
}
.menu-line-con:hover .menu-text > *{
    color: #8074F2;
}

@media screen and (max-width: 991px) {
    .menu-text,
    .header-buttons-search > * span {
        display: none;
    }
    .header .logo svg {
        width: 164px;
    }
    .header-buttons-search {
        padding-left: 30px
    }
    .header-right {
        margin-left: 29px;
    }
}

@media screen and (max-width: 767px) {
    #wrapper{
        padding-top: 128px;
    }
    #wrapper.fixed-header-pt{
        padding-top: 72px;
    }
    .header.fixed-header,
    .header{
        height: 128px;
        padding-bottom: 6px;
    }
    .header{
        -webkit-transition: top .3s linear;
        transition: top .3s linear;
    }
    .header.fixed-header{
        top: -56px;
    }
    .header-con {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
    }
    .header-left{
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
    }
    .header.fixed-header .logo svg,
    .header .logo svg {
        width: 150px
    }
    .header-center{
        -webkit-box-ordinal-group: 4;
            -ms-flex-order: 3;
                order: 3;
        margin-left: 0;
        width: 100%;
        padding-top: 15px;
    }
    .header.fixed-header .header-buttons-insurance > *,
    .header .header-buttons-insurance > * {
        padding: 13px 10px 12px;
        max-width: 145px;
    }
    .header-buttons-online{
        margin-left: 15px;
    }
    .header.fixed-header .header-buttons-online > * span,
    .header .header-buttons-online > * span{
        padding: 12px 16px 10px;
    }
    .header-buttons-search{
        padding-left: 0;
        margin-left: auto;
    }
    .header-right{
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
        margin-left: 0;
    }
}

@media screen and (max-width: 429px) {
    #wrapper {
        padding-top: 112px
    }
    #wrapper.fixed-header-pt {
        padding-top: 66px
    }
    .header.fixed-header, 
    .header {
        height: 112px;
        padding-bottom: 8px;
    }
    .header.fixed-header {
        top: -46px
    }
    .header.fixed-header .logo svg, 
    .header .logo svg {
        width: 120px
    }
    .header-buttons-insurance > * {
        font-size: 12px;
        border-radius: 7px
    }
    .header-buttons-online {
        margin-left: 10px
    }
    .header.fixed-header .header-buttons-insurance > *,
    .header .header-buttons-insurance > * {
        padding: 11px 0 9px;
        max-width: 115px;
    }
    .header-buttons-online > * svg{
        width: 15px;
        height: 15px;
    }
    .header-buttons-online > * span {
        font-size: 12px;
    }
    .header.fixed-header .header-buttons-online > * span, 
    .header .header-buttons-online > * span {
        padding: 11px 9px 10px;
    }
}

@media screen and (max-width: 350px) {
    .header-buttons-search {
        display: none;
    }
    .header-buttons-insurance{
        margin-right: 5px;
        width: calc(44% - 5px);
    }
    .header.fixed-header .header-buttons-insurance > *, 
    .header .header-buttons-insurance > * {
        max-width: unset;
        width: 100%;
    }
    .header-buttons-online {
        margin-left: 5px;
        width: calc(56% - 5px);
    }
    .header-buttons-online > *{
        width: 100%;
    }
    .header-buttons-online > *{
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
}

/* --------------------------- Header END ---------------------------------- */

/* --------------------------- Drop down menu START ---------------------------------- */

.drop-down-menu{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
}

.header .main-menu {
    border: 1px solid #e3e0fc;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: dashed 2px #786edc;
    background-color: #fff;
    z-index: 4;
    padding: 40px 80px 60px;
/*    opacity: 0;
    visibility: hidden;*/
}
.header .search-block {
    margin-bottom: 40px;
}
.header .form-group {
    width: 100%;
    max-width: 570px;
    height: 60px;
    position: relative;
    border-radius: 5px;
    margin: 0 auto;
}
.header .input-wrapper {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
}
.header .form-group .input-wrapper input{
    -webkit-transition: all .3s;
    transition: all .3s;
    outline-offset: -2px;
    -webkit-appearance: none;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding: 10px 30px;
    border: 1px solid #e3e0fc;
    -webkit-font-variant-ligatures: none;
            font-variant-ligatures: none;
    padding-right: 170px;
}
.header .input-wrapper input:focus{
    border-color: #8074f2;
}
.header .input-wrapper .input-wrapper-in-p {
    position: absolute;
    z-index: 1;
    color: #222;
    font-size: 14px;
    font-weight: 400;
    top: 16px;
    left: 30px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    display: block;
    padding: 2px 6px;
    border-radius: 5px;
    background-color: #fff;
}
.header .input-wrapper input:focus + .input-wrapper-in-p,
.header .input-wrapper input.focus + .input-wrapper-in-p {
    top: -14px;
    font-size: 12px;
    color: #8074f2;
    -webkit-transition: .3s;
    transition: .3s;
}
.header .form-group button {
    outline: none;
    cursor: pointer;
    -webkit-appearance: button;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: none;
    font-family: "Baloo";
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    background-color: #8074f2;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    width: 150px;
    right: 0;
    top: 0;
    height: 100%;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.header .form-group button:hover {
    background-color: #79a3ef;
    color: #fff;
}
.header .main-menu .row-info {
    margin-top: 60px;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.header .main-menu .main-menu-about li {
    margin-bottom: 18px;
}
.header .main-menu .main-menu-about li:last-child {
    margin-bottom: 0;
}
.header .main-menu .main-menu-about li a {
    text-decoration: none;
    color: #444;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.header .main-menu .main-menu-about li a:hover {
    text-decoration: underline;
}
.header .main-menu-follow{
    display: table;
    margin-left: auto;
}
.header .main-menu-title {
    font-family: "Baloo";
    font-size: 24px;
    color: #444;
    margin-bottom: 20px;
}
.header .main-menu-follow ul li {
    display: inline-block;
    margin-right: 15px;
    position: relative;
}
.header .main-menu-follow ul li:last-child {
    margin-right: 0;
}
.header .main-menu-follow ul li a {
    text-decoration: none;
    display: block;
    position: relative;
}
.header .main-menu-follow ul li a img{
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 767px) {
    .header .main-menu .row-info {
        margin-top: 0;
    }
    .header .search-block {
        margin-bottom: 30px;
    }
    .header .main-menu .main-menu-about {
        margin-bottom: 30px;
        text-align: center;
    }
    .header .main-menu .main-menu-follow {
        text-align: center;
        margin-right: auto;
    }
    .header .main-menu-follow ul li {
        margin-right: 10px;
    }
    .header .main-menu {
        padding: 30px 20px;
    }
    .header .form-group button {
        font-size: 14px;
    }
}

@media screen and (max-width: 575px) {
    .drop-down-menu {
        padding-left: 0;
        padding-right: 0;
    }
    .header .form-group {
        height: 46px;
        font-size: 12px;
    }
    .header .form-group .input-wrapper input {
        padding: 10px 20px;
        padding-right: 120px;
    }
    .header .input-wrapper .input-wrapper-in-p {
        left: 15px;
        top: 10px;
    }
    .header .form-group button {
        font-size: 12px;
        width: 100px;
    }
    .header .main-menu-title {
        font-size: 20px;
    }
}

/* --------------------------- Drop down menu END ---------------------------------- */