/* --------------------------- Modal START ---------------------------------- */

#wrapper.modal-open {
    overflow: hidden;
}
.fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop.show {
    opacity: .5;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}
.modal-dialog-centered {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
            align-items: center;
    min-height: calc(100% - (0.5rem*2));
}
.modal.fade .modal-dialog {
    transition: -webkit-transform .3s ease-out;
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    transition: transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform: translate(0,-50px);
    transform: translate(0,-50px);
}
.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
}
.modal-dialog-centered:before {
    display: block;
    height: calc(100vh - (0.5rem*2));
    content: "";
}
.answer-email {
    border-radius: 10px;
    background-color: #fdf5d1;
    -webkit-box-shadow: 0 7px 68px 0 rgb(22 22 22 / 10%);
            box-shadow: 0 7px 68px 0 rgb(22 22 22 / 10%);
    padding: 10px;
    max-width: 370px;
    width: 100%;
    margin: 0 auto;
    pointer-events: auto;
    position: relative;
}
.answer-email.clinic-bug {
    max-width: 470px;
    background-color: #fff;
}
.close-modal {
    position: absolute;
    right: 30px;
    top: 20px;
    color: #8074f2;
}
.close-modal svg {
    display: inline-block;
    fill: #8074f2;
}
.answer-email__inner {
    padding: 50px 10px;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    border-width: 2px;
    border-color: #fece00;
    border-style: dotted;
    border-radius: 5px;
}
.answer-email.clinic-bug .answer-email__inner {
    border: 0;
}
.send-clinic-bug{
    width: 100%;
}
.input-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
.input-wrapper h5 {
    font-family: "Baloo";
    color: #444;
    font-size: 20px;
    margin-bottom: 20px;
}
.answer-email.clinic-bug .answer-email__inner h5 {
    margin-bottom: 0;
    margin-top: 20px;
}
.answer-email.clinic-bug .answer-email__inner span {
    margin-bottom: 0;
    margin-top: 20px;
    font-family: "Baloo";
    color: #444;
    font-size: 20px;
    display: block;
}
.input-wrapper input,
.input-wrapper textarea {
    -webkit-transition: all .3s;
    transition: all .3s;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding: 10px 30px;
    border: 1px solid #e3e0fc;
    -webkit-font-variant-ligatures: none;
            font-variant-ligatures: none;
    font-family: "SFProDisplay";
    font-size: 16px;
}
.input-wrapper input:focus,
.input-wrapper textarea:focus {
    border-color: #8074f2;
}
.input-wrapper textarea {
    resize: none;
    outline: none;
}
.send-clinic-bug button {
    outline: 0;
    background: none;
    border: 0;
    cursor: pointer;
    margin: 20px auto 0 auto;
    display: table;
}
.send-clinic-bug button span{
    border-radius: 10px;
    font-size: 22px;
    text-transform: uppercase;
    padding: 11px 43px 7px 41px;
}

.answer-email .btn {
    max-width: 170px;
    height: 48px;
    background-color: #8074f2;
    border-top: 7px solid #8074f2;
    border-bottom: 7px solid #6056bc;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.answer-email .btn.btn-purple:hover {
    border-color: #6056bc;
    background-color: #6056bc;
    color: #fff;
    -webkit-box-shadow: 0px 7px 21px 0px rgb(128 116 242 / 58%);
            box-shadow: 0px 7px 21px 0px rgb(128 116 242 / 58%);
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.answer-email.clinic-bug .answer-email__inner .btn {
    margin: 20px auto 0 auto;
}

@media screen and (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem*2));
    }
    .modal-dialog-centered:before {
        height: calc(100vh - (1.75rem*2))
    }
}

@media screen and (max-width: 575px) {
    .input-wrapper input,
    .input-wrapper textarea {
        padding: 10px 20px;
    }
}

/* --------------------------- Modal END ---------------------------------- */
