html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
}

sub,
sup {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 70%;
}

*:focus {
    outline: 0;
}

ol,
ul {
    list-style: none;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

caption,
th,
td {
    text-align: left;
    font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
}

blockquote,
q {
    quotes: "" "";
}

a {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
}

a,
a:hover,
a:active,
a:focus {
    text-decoration: none;
    color: inherit;
}

a:focus {
    outline: none;
    outline-offset: 0;
}

.clear {
    clear: both;
}

input,
textarea,
button {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    display: block;
    padding: 0;
    margin: 0;
}

input[type='submit'] {
    -webkit-appearance: none;
    cursor: pointer;
}

select:hover,
select:active,
input[type='radio']+label,
input[type='checkbox']+label {
    cursor: pointer;
}

input,
textarea {
    width: 100%;
    font-family: inherit;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 0;
    background: transparent;
}

*,
 :after,
 :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

svg,
img {
    display: block;
    max-width: 100%;
}
img {
    height: auto;
}

::-webkit-scrollbar-track {
    background: #fff;
}

::-webkit-scrollbar-thumb {
    background: #8074f2;
}

::-webkit-scrollbar-thumb {
    width: 10px;
    height: 0;
    border-radius: 5px;
}

::-webkit-scrollbar {
    width: 10px;
    height: 0;
}

@font-face {
    font-family: "Baloo";
    src: url("../../fonts/Baloo/Baloo-Regular.eot");
    src: url("../../fonts/Baloo/Baloo-Regular.woff2") format("woff2"), url("../../fonts/Baloo/Baloo-Regular.woff") format("woff"), url("../../fonts/Baloo/Baloo-Regular.ttf") format("truetype"), url("../../fonts/Baloo/Baloo-Regular.svg#Baloo") format("svg"), url("../../fonts/Baloo/Baloo-Regular.eot?#iefix") format("embedded-opentype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SFProDisplay";
    src: url("../../fonts/SFProDisplay/SFProDisplay-Regular.eot");
    src: url("../../fonts/SFProDisplay/SFProDisplay-Regular.woff2") format("woff2"), url("../../fonts/SFProDisplay/SFProDisplay-Regular.woff") format("woff"), url("../../fonts/SFProDisplay/SFProDisplay-Regular.ttf") format("truetype"), url("../../fonts/SFProDisplay/SFProDisplay-Regular.svg#SFProDisplay") format("svg"), url("../../fonts/SFProDisplay/SFProDisplay-Regular.eot?#iefix") format("embedded-opentype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SFProDisplay";
    src: url("../../fonts/SFProDisplay/SFProDisplay-Medium.eot");
    src: url("../../fonts/SFProDisplay/SFProDisplay-Medium.woff2") format("woff2"), url("../../fonts/SFProDisplay/SFProDisplay-Medium.woff") format("woff"), url("../../fonts/SFProDisplay/SFProDisplay-Medium.ttf") format("truetype"), url("../../fonts/SFProDisplay/SFProDisplay-Medium.svg#SFProDisplay") format("svg"), url("../../fonts/SFProDisplay/SFProDisplay-Medium.eot?#iefix") format("embedded-opentype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "SFProDisplay";
    src: url("../../fonts/SFProDisplay/SFProDisplay-Bold.eot");
    src: url("../../fonts/SFProDisplay/SFProDisplay-Bold.woff2") format("woff2"), url("../../fonts/SFProDisplay/SFProDisplay-Bold.woff") format("woff"), url("../../fonts/SFProDisplay/SFProDisplay-Bold.ttf") format("truetype"), url("../../fonts/SFProDisplay/SFProDisplay-Bold.svg#SFProDisplay") format("svg"), url("../../fonts/SFProDisplay/SFProDisplay-Bold.eot?#iefix") format("embedded-opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

html,
body {
    width: 100%;
    height: 100%;
    font-family: "SFProDisplay";
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    line-height: 156%;
    color: #444444;
}

#wrapper {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
    padding-top: 100px;
    -webkit-transition: padding-top .3s linear;
    transition: padding-top .3s linear;
}

#wrapper.fixed-header-pt {
    padding-top: 66px;
}

#wrap {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.content-block-list {
    padding-top: 26px;
}

.cbl-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.cbl-left{
    width: calc(100% - 375px - 30px);
}

.cbl-right{
    width: 375px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-left: auto;
    margin-top: -128px;
}

.cbl-right-outer{
    position: relative;
    height: 100%;
}

.cbl-right-inner{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 130px;
}

.bt-no-lapa,
.bt-no-lapa-small{
    display: inline-block;
    font-family: "Baloo";
    font-weight: 400;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    border-width: 2px;
    border-style: solid;
}

.bt-no-lapa{
    font-size: 16px;
    border-radius: 8px;
}
.bt-no-lapa-small{
    font-size: 13px;
    border-radius: 10px;
}
.bt-color-purple{
    color: #8074F2;
    border-color: #8074F2;
    background-color: transparent;
    -webkit-transition: background-color 0.3s ease, color 0.3s ease, padding .3s linear;
    transition: background-color 0.3s ease, color 0.3s ease, padding .3s linear;
}
.bt-color-purple:hover{
    color: #ffffff;
    background-color: #8074F2;
}
.bt-lapa-small,
.bt-lapa {
    position: relative;
    display: inline-block;
}
.bt-lapa{
    padding-bottom: 8px;
}
.bt-lapa-small{
    overflow: hidden;
    border-radius: 6px;
}
.bt-lapa-small span,
.bt-lapa span{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-family: Baloo;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    letter-spacing: 0.07em;
    position: relative;
}
.bt-lapa-small span{
    padding: 16px 27px;
    font-size: 13px;
    -webkit-transition: padding .3s linear;
    transition: padding .3s linear;
}
.bt-lapa span {
    padding: 18px 29px 14px 27px;
    font-size: 16px;
    border-radius: 8px;
}
.bt-lapa svg {
    margin-right: 16px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
}
.bt-lapa-small svg {
    margin-right: 10px;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
}
.bt-lapa:before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    -webkit-transition: top 0.3s ease, bottom 0.3s ease;
    transition: top 0.3s ease, bottom 0.3s ease;
}
.bt-lapa:hover:before{
    top: -8px;
    bottom: 8px;
}
.bt-color-yellow-fill:before{
    background-color: #DFB506;
}
.bt-color-yellow-fill span{
    color: #8074F2;
    background: -webkit-gradient(linear, left top, left bottom, from(#FFCE00), to(#FAD53D));
    background: linear-gradient(180deg, #FFCE00 0%, #FAD53D 100%);
}
.bt-color-purple-fill:before{
    background-color: #6056bc;
}
.bt-color-purple-fill span{
    color: #fff;
    background-color: #8074f2;
}
.bt-color-yellow:before,
.bt-color-yellow:after{
    content: '';
    display: block;
    position: absolute;
}
.bt-color-yellow:before{
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(#FFCE00), to(#FAD53D));
    background: linear-gradient(180deg, #FFCE00 0%, #FAD53D 100%);
}
.bt-color-yellow:after{
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    background-color: #ffffff;
    border-radius: 4px;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}
.bt-color-yellow:hover:after{
    opacity: 1;
}
.bt-color-yellow span{
    color: #8074F2;
    position: relative;
    z-index: 2;
}
.bt-lapa + .bt-sub-desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 133%;
    text-align: center;
    color: #444444;
    padding-top: 6px;
}

.round-shadow-purple{
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 4px 40px rgba(128, 116, 242, 0.15);
            box-shadow: 0px 4px 40px rgba(128, 116, 242, 0.15);
    border-radius: 20px;
}

.round-border-purple{
    border: 1px solid #8074F2;
    border-radius: 20px;
}

.star-raiting-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.star-raiting-list span{
    display: block;
    width: 15px;
    height: 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 15 15' xml:space='preserve'%3E%3Cpath fill='%238074F2' d='M12,15H3c-1.7,0-3-1.3-3-3V3c0-1.7,1.3-3,3-3h9c1.7,0,3,1.3,3,3v9C15,13.7,13.7,15,12,15z'/%3E%3Cpath fill='%23FFFFFF' d='M10.3,12.7l-2.8-1.5l-2.8,1.5c-0.4,0.2-0.9-0.2-0.8-0.6L4.5,9L2.2,6.8C1.9,6.5,2,5.9,2.5,5.9l3.1-0.5L7,2.6c0.2-0.4,0.8-0.4,1,0l1.4,2.8l3.1,0.5c0.5,0,0.6,0.6,0.3,0.9L10.5,9l0.5,3.1C11.2,12.5,10.7,12.9,10.3,12.7z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-left: 5px;
}
.star-raiting-list span:first-child{
    margin-left: 0;
}

.cbl-block-icon-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}
.cbl-block-icon-title svg {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
}
.cbl-block-icon-title p {
    font-weight: 700;
    font-size: 22px;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
    padding-left: 10px;
}

.cbl-right-title > *{
    font-weight: 700;
    font-size: 22px;
    line-height: 1;
    text-transform: uppercase;
    color: #444444;
}

.cbl-right-popular-hours-select-day-selected:after,
.cbl-review-block-nav-select:after{
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 14px;
    right: 13px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.33338 2.00002C1.42095 1.9999 1.50767 2.01709 1.58856 2.05061C1.66945 2.08414 1.74291 2.13333 1.80472 2.19536L4.00005 4.39036L6.19539 2.19536C6.32112 2.07392 6.48952 2.00672 6.66432 2.00824C6.83912 2.00976 7.00633 2.07987 7.12993 2.20348C7.25354 2.32708 7.32365 2.49429 7.32517 2.66909C7.32669 2.84389 7.25949 3.01229 7.13805 3.13802L4.47139 5.80469C4.34637 5.92967 4.17683 5.99988 4.00005 5.99988C3.82328 5.99988 3.65374 5.92967 3.52872 5.80469L0.862051 3.13802C0.770903 3.04401 0.709253 2.92539 0.684698 2.79677C0.660143 2.66815 0.673758 2.53516 0.723864 2.41418C0.773969 2.29321 0.858376 2.18953 0.966682 2.11594C1.07499 2.04235 1.20246 2.00206 1.33338 2.00002Z' fill='%238074F2'/%3E%3C/svg%3E");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    pointer-events: none;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.btn {
    font-family: Baloo;
    border: 2px solid #8074f2;
    border-radius: 10px;
    height: 66px;
    max-width: 266px;
    width: 100%;
    font-size: 20px;
    text-transform: uppercase;
    color: #8074f2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 5px 10px;
    z-index: 2;
    position: relative;
    text-decoration: none;
}
.btn-purple {
    border: none;
    color: #fff;
    background-color: #8074f2;
    border-top: 7px solid #8074f2;
    border-bottom: 7px solid #6056bc;
}
.btn-purple:hover {
    border-color: #6056bc;
    background-color: #6056bc;
    color: #fff;
    -webkit-box-shadow: 0px 7px 21px 0px rgb(128 116 242 / 58%);
            box-shadow: 0px 7px 21px 0px rgb(128 116 242 / 58%);
}
.btn-large {
    max-width: 400px;
    height: 87px;
    font-size: 22px;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.answer-email .close {
    position: absolute;
    top: 20px;
    right: 26px;
    opacity: 1;
}
.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.answer-email .close span {
    color: #8074f2;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

@media screen and (max-width: 991px) {
    .cbl-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .cbl-left {
        width: 100%
    }
    .cbl-right {
        width: 100%;
        margin-left: 0;
        margin-top: 0;
    }
    .cbl-right-inner {
        position: static;
        padding-top: 30px;
    }
}

@media screen and (max-width: 575px) {
    .btn-large {
        height: 72px;
        font-size: 18px;
    }
}

@media screen and (max-width: 429px) {
    .content-block-list {
        padding-top: 12px
    }
    .cbl-block-icon-title svg {
        width: 16px;
        height: 16px;
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
    }
    .cbl-block-icon-title p {
        font-size: 16px;
        padding-left: 8px;
    }
    .bt-no-lapa {
        font-size: 14px;
    }
    .cbl-right-title > * {
        font-size: 16px;
    }
    .round-border-purple {
        border-radius: 15px;
    }
    .cbl-right-inner {
        padding-top: 20px;
    }
    .round-shadow-purple {
        border-radius: 15px;
    }
}
